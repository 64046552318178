import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import s from "./../Admin.module.css";

export default function ScoreUser(props) {
  const { score } = props;

  // Проверяем, что score — это массив и не пустой
  if (!Array.isArray(score) || score.length === 0) {
    return <div>No score data available.</div>;
  }

  // Определяем колонки
  const columns = [
    { field: "InvId", headerName: "Номер счёта", flex: 1 },
    { field: "OutSum", headerName: "Сумма", flex: 1 },
    { field: "createdAt", headerName: "Дата создания", flex: 1 },
    { field: "userId", headerName: "ID пользователя", flex: 1 },
    {
      field: "paymentStatus",
      headerName: "Статус платежа",
      flex: 1,
      renderCell: (params) => (params.value ? "Оплачено" : "Не оплачено"),
    },
  ];

  // Преобразуем данные для таблицы, задаем id для каждой строки
  const rows = score.map((item, index) => ({
    id: item.InvId || index, // Используем InvId как уникальный идентификатор строки, либо индекс как fallback
    ...item,
  }));

  return (
    <div
      className={s.blockPageAdminUser}
      style={{ height: "100%", width: "100%" }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 30, 50]}
        checkboxSelection
      />
    </div>
  );
}
